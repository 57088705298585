var submitted = false;
$(document)
  // to prevent form from submitting upon successful validation
  .on("submit", function(ev) {
    if(ev.target.id == "form-evaluatie"){
      if(submitted == true){
        ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
      }else{
        submitted = true;
        console.log("Submitted");
        // Your text from a form field, converted to lower case
        var textFieldValueLower = document.getElementById('open_belangstelling_in_training').value.toLowerCase();

        // Array of words you want to check against the text, also converted to lower case
        var positiveWords = ['ja', 'wel', 'misschien'].map(word => word.toLowerCase());
        var negativeWords = ['nee', 'niet'].map(word => word.toLowerCase());

        // Check if the text contains any word from the array, case-insensitively
        var containsPositive = positiveWords.some(word => textFieldValueLower.includes(word));
        var containsNegative = negativeWords.some(word => textFieldValueLower.includes(word));

        // Use the result as needed
        if (containsPositive) {
          dataLayer.push({'interestedInMore': true});
        }else if(containsNegative){
          dataLayer.push({'interestedInMore': false});
        }else{
          dataLayer.push({'interestedInMore': 'unknown'});
        }
        dataLayer.push({'event': 'EvaluatieIngevuld'});
      }
    }
  });

$('form #training').on('change', function(){
    if($(this).val() != "0"){
        $('.hidebydefault').show();
    }else{
        $('.hidebydefault').hide();

    }
});
document.addEventListener("DOMContentLoaded", function () {
    const sourceField = document.getElementById("custom_open[wat_was_voor_jou_het_meest_waardevol]");
    const targetField = document.getElementById("open_goed_aan_training");

    if (sourceField && targetField) {
        sourceField.addEventListener("input", function () {
            targetField.value = sourceField.value;
        });
    }
});
document.addEventListener("DOMContentLoaded", () => {
    const input = document.getElementById("tag-input");
    const autocompleteList = document.getElementById("autocomplete-list");
    const selectedTagsContainer = document.getElementById("selected-tags");
    const hiddenTagsInput = document.getElementById("hidden-tags");

    const selectedTags = new Set();
    let debounceTimeout;
    if(selectedTagsContainer){
        // Add custom or selected tags
        const addTag = (tag) => {
            tag = tag.trim();
            if (tag && !selectedTags.has(tag)) {
                selectedTags.add(tag);

                // Create a visual tag element
                const tagElement = document.createElement("div");
                tagElement.className = "tag";
                tagElement.innerHTML = `${tag} <span class="remove">x</span>`;

                tagElement.querySelector(".remove").addEventListener("click", () => {
                    selectedTags.delete(tag);
                    selectedTagsContainer.removeChild(tagElement);
                    updateHiddenInput();
                });

                selectedTagsContainer.appendChild(tagElement);

                // Update the hidden input
                updateHiddenInput();
            }
        };
    }
    if(hiddenTagsInput){
        // Update the hidden input field with the current tags
        const updateHiddenInput = () => {
            hiddenTagsInput.value = Array.from(selectedTags).join(",");
        };
    }
    if(input){
        // Handle input changes with debounce
        input.addEventListener("input", () => {
            const query = input.value.trim();

            // Clear the previous debounce timer
            clearTimeout(debounceTimeout);

            // Set a new debounce timer
            debounceTimeout = setTimeout(async () => {
                if (query.length > 0) {
                    const response = await fetch(`/zoeken/autocomplete?q=${query}`);
                    const suggestions = await response.json();

                    autocompleteList.innerHTML = ""; // Clear old suggestions
                    suggestions.forEach((tag) => {
                        const li = document.createElement("li");
                        li.textContent = tag["value"];
                        li.addEventListener("click", () => {
                            addTag(tag["value"]);
                            input.value = "";
                            autocompleteList.innerHTML = "";
                        });
                        autocompleteList.appendChild(li);
                    });
                } else {
                    autocompleteList.innerHTML = "";
                }
            }, 300); // Wait for 300ms after the user stops typing
        });

        // Add custom tag on Enter, Tab, or blur
        input.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.key === "Tab") {
                event.preventDefault();
                addTag(input.value);
                input.value = ""; // Clear the input
                autocompleteList.innerHTML = ""; // Clear the suggestions
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const sliders = document.querySelectorAll(".tb-slider");
    if(sliders){
        sliders.forEach((slider) => {
            // Create the tooltip element
            const tooltip = document.createElement("div");
            tooltip.className = "range-tooltip";
            tooltip.style.position = "absolute";
            tooltip.style.display = "none";
            tooltip.style.background = "#333";
            tooltip.style.color = "#fff";
            tooltip.style.padding = "5px 10px";
            tooltip.style.borderRadius = "5px";
            tooltip.style.fontSize = "12px";
            tooltip.style.pointerEvents = "none";
            tooltip.style.zIndex = "10";
            tooltip.style.whiteSpace = "nowrap";

            // Append the tooltip to the document body
            document.body.appendChild(tooltip);

            // Update tooltip position and value
            const updateTooltip = () => {
                const sliderRect = slider.getBoundingClientRect();
                tooltip.textContent = slider.value;

                // Position tooltip
                const tooltipWidth = tooltip.offsetWidth;
                const tooltipHeight = tooltip.offsetHeight;
                const handlePosition =
                    (slider.offsetWidth * (slider.value - slider.min)) / (slider.max - slider.min);

                // Adjust for scrolling and position above the slider handle
                const tooltipLeft = sliderRect.left + handlePosition - tooltipWidth / 2;
                const tooltipTop = sliderRect.top + window.scrollY - tooltipHeight - 5; // Adjust for scroll

                tooltip.style.left = `${tooltipLeft}px`;
                tooltip.style.top = `${tooltipTop}px`;
                tooltip.style.display = "block";
            };

            // Add event listeners
            slider.addEventListener("input", updateTooltip);
            slider.addEventListener("mousemove", updateTooltip);
            slider.addEventListener("mouseleave", () => {
                tooltip.style.display = "none";
            });
            // add event listener for touch devices
            slider.addEventListener("touchmove", updateTooltip);
            slider.addEventListener("touchend", () => {
                tooltip.style.display = "none";
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    // Select all sliders and attach markers
    const sliders = document.querySelectorAll(".tb-slider");

    if(sliders){
        sliders.forEach(slider => {
            const markersContainer = slider.nextElementSibling; // ".markers"
            const min = parseInt(slider.min);
            const max = parseInt(slider.max) ;
            const step = parseInt(slider.step);
            const totalSteps = (max - min) / step;

            // Create markers dynamically
            for (let i = 0; i <= totalSteps; i++) {
                const marker = document.createElement("div");
                marker.classList.add("marker");
                marker.style.left = `${(i / totalSteps) * 100}%`; // Position marker

                const label = document.createElement("span");
                //label.textContent = value; // Add marker value
                marker.appendChild(label);

                markersContainer.appendChild(marker);
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const steps = document.querySelectorAll(".form-step");
    const progressTracker = document.querySelectorAll(".progress-tracker .step");
    const prevBtn = document.getElementById("prev-btn");
    const nextBtn = document.getElementById("next-btn");
    const submitBtn = document.getElementById("submit-btn");

    let currentStep = 0; // Initialize the current step
  
    // Function to update step visibility and button states
    const updateSteps = () => {
        if(steps){
            steps.forEach((step, index) => {
                step.style.display = index === currentStep ? "block" : "none";
            });

            progressTracker.forEach((trackerStep, index) => {
                trackerStep.classList.toggle("active", index === currentStep);
            });

            if(prevBtn){
            prevBtn.style.display = currentStep > 0 ? "inline-block" : "none";
            }
            if(nextBtn){
            nextBtn.style.display = currentStep < steps.length - 1 ? "inline-block" : "none";
            }
            if(submitBtn){
            submitBtn.style.display = currentStep === steps.length - 1 ? "inline-block" : "none";
            }
        }
    };
    if(nextBtn){
        // Handle Next button click
        nextBtn.addEventListener("click", () => {
            if (currentStep < steps.length - 1) currentStep++;
            updateSteps();
        });
    }
    if(prevBtn){
        // Handle Previous button click
        prevBtn.addEventListener("click", () => {
            if (currentStep > 0) currentStep--;
            updateSteps();
        });
    }
    if(steps){
        updateSteps(); // Initialize the view
    }
});
